.navContainer {
  width: 100%;
  position: fixed;
  z-index: 22;
  background-color: black;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
  border-bottom: 2px solid var(--bg-violet-clair);
  padding: 5px 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.logo-container a {
  cursor: pointer;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  max-width: 250px;
  min-width: 150px;
  padding: 10px 30px 12px 0px;
}
.font-bars {
  display: none;
  color: white;
  cursor: pointer;
}
.nav-content {
  display: flex;
  height: 80px;
}
.link-container {
  display: flex;
}
.linkRow {
  text-decoration: none;
  color: white;
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 5px 30px;
  justify-content: center;
  border-radius: 1px;
  font-size: 1em;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.linkColumn {
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 8px 30px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.linkRow:hover {
  color: var(--rose);
}
.linkRow:last-child {
  margin-right: 0px;
}
.linkColumn:last-child {
  margin-bottom: 10px;
}
.linkColumn:hover {
  color: var(--rose);
}

.nav-below {
  padding: 20px 50px;
  background-color: var(--bg-violet-clair);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  bottom: -7px;
  width: 100%;
  padding: 0;
  height: 90px;
  z-index: -1;
  left: 0;
}

.actNav {
  color: var(--rose);
}

@media (max-width: 1200px) {
  .font-bars {
    display: flex;
  }
  .linkRow {
    display: none;
  }
  .logo {
    width: 60%;
    max-width: 320px;
  }
  .link-container {
    display: none;
  }
  .logo-container {
    width: 100%;
    justify-content: space-between;
  }
}
@media (min-width: 1100px) {
  .link-container {
    flex-direction: row;
    display: flex;
  }
}
