.containerTitles {
  position: relative;
}
#h2 {
  position: absolute;
  bottom: -8px;
  left: 0px;
  font-size: 80px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}
#p {
  font-size: 120px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  #p {
    font-size: 60px;
  }
  #h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 431px) {
  #p {
    font-size: 50px;
  }
  #h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 370px) {
  #p {
    font-size: 40px;
  }
  #h2 {
    font-size: 20px;
  }
}
