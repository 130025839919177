.borderWhite {
  border-top: 10px solid white;
}
.borderViolet {
  border-top: 10px solid var(--bg-violet-clair);
}
.borderVioletFonce {
  border-top: 10px solid var(--bg-violet-fonce);
}

.containerImgPrincipal {
  height: 100vh;
}
.containerWhite {
  background: white;
}
.bandeauAbsolute {
  position: absolute;
  top: 4px;
  /* left: 4px; */
  width: 100%;
}
.bandeau1Partenaire {
  position: absolute;
  top: -84px;
  width: 100%;
  left: 0;
}
.second {
  top: -80px;
}
.bandeauPourPartenaire {
  transform: rotateY(180deg);
  position: absolute;
  top: -80px;
  /* left: -10px; */
  width: 100%;
}
.borderBottomWhite {
  border-bottom: 3px solid white;
}
.translateBandeau {
  transform: translateY(-80px) rotateY(180deg);
  border-bottom: 4px solid var(--bg-violet-clair);
}
/* .bandeauPartenaireBIs{
  position: "absolute", top: 4, left: 4, width: "100%"
} */

/* Agenda */

.btnAgenda {
  margin: 50px auto 0px auto;
  width: fit-content;
}
.containerActuHome {
  padding: 100px 0;
  background-color: var(--bg-violet-clair);
}
.sliderActuHome {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.containerSliderActuHome {
  background-color: var(--bg-violet-clair);
  /* padding: 50px 0 100px 0; */
}

.containerAgenda .swiper-button-next {
  color: var(--bg-violet-clair);
}
.containerAgenda .swiper-button-prev {
  color: var(--bg-violet-clair);
}
/* caroussel spectacle */
.btnCarousselHome {
  display: flex;
  justify-content: center;
  margin: 40px auto 80px auto;
}
.btnCarousselHome button {
  background: white;
  margin: 50px 0;
  border: none;
  font-weight: bold;
  color: black;
}
.btnCarousselHome button:hover {
  opacity: 0.8;
  color: black;
  background-color: white;
}
.btnActuHome {
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
}
.containerAgendaHome {
  padding: 100px 0;
}
.btnActuHome a {
  text-transform: uppercase;
  font-weight: bold !important;
}
.descriptionSpectacleHome {
  line-height: 24px;
  margin-bottom: 60px;
  padding: 0 20px;
}
.containerPadding100 {
  padding-top: 130px;
  padding-bottom: 130px;
}
.containerPadding150 {
  margin: 150px auto;
}
.containerPadding50 {
  padding: 50px 0 100px 0;
}
.containerMargin100 {
  margin: 100px auto;
}
.containerSpectaclesHome {
  color: white;
  display: flex;
  justify-content: space-between;
}
.carousselSpectacles {
  margin: 100px 0 0px 0;
}
.violet {
  background-color: var(--bg-violet-clair);
  color: white;
}
.violet:hover {
  color: white;
}
.m-r-5 {
  margin-right: 10px;
}
.grisFonce {
  background-color: var(--gris);
  color: white;
}
.grisFonce:hover {
  color: white;
}
.carousselSpectacles .swiper-button-next {
  color: white;
}
.carousselSpectacles .swiper-button-prev {
  color: white;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-pagination {
  bottom: 0;
}
.ContainerTextSpectacleHome {
  /* max-width: 300px; */
  flex: 1;
}
.flex2 {
  flex: 2;
}

/* Actus */
.containerActusHome {
  background-color: var(--bg-violet-clair);
}

.sliderActuHome {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.containerSliderActuHome {
  background-color: var(--bg-violet-clair);
}
@media screen and (max-width: 900px) {
  .containerActusHome {
    margin-left: 0vw;
  }
}

/* references */

.textReferenceHome .txtRef {
  color: black;
  font-weight: normal;
  line-height: 30px;
  font-size: 16.52px;
  font-weight: 700;
  margin-bottom: 20px;
}
.containerAgenda .btnAgenda {
  background-color: var(--bg-violet-clair);
  color: white;
}
.containerSliderActuHome .btnAgenda {
  color: black;
  background-color: white;
}

.sliderActuHome .swiper-button-next {
  color: white;
}
.sliderActuHome .swiper-button-prev {
  color: white;
}
.containerContactHome {
  padding: 40px 0 100px 0;
}
.textReferenceHome span {
  display: block;
  font-size: 14.52px;
  color: #606992;
}
/*  form */
.containerFormulaireHome {
  max-width: 450px;
}
.containerFormulaireHome p {
  margin: 0;
  font-size: 14px;
}
.containerAdresseHome {
  margin-bottom: 20px;
}
.containerAdresseHome p,
.containerTelSiretHome p {
  font-size: 16px;
  line-height: 20px;
}
.containerTelSiretHome {
  margin-bottom: 20px;
}

/* map */
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 460px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.btnTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.mapouter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.dflexResponsive {
  display: none;
  margin: 100px auto 0 auto;
}
@media screen and (max-width: 1200px) {
  .map {
    display: none;
  }
  .containerFormulaireHome {
    margin: 0 auto;
  }
  .sliderActuHome {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .containerActus {
    margin: 30px auto;
  }
  .containerActus:first-child {
    margin-top: 30px;
  }
}
@media screen and (max-width: 992px) {
  .containerSpectaclesHome {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .descriptionSpectacleHome {
    text-align: center;
  }
}
@media screen and (max-width: 780px) {
  .noneResponsive {
    display: none;
  }
  .dflexResponsive {
    display: flex;
  }
  .btnTitle {
    justify-content: center;
  }
}
