.footerContainer {
  padding: 20px;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-between;
  line-height: 20px;
}
.footerContainer a {
  font-size: 13px;
}
.mentions {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: space-between;
}
.facebookFooter svg,
.contactFooter svg,
.logoFooterContainer svg {
  color: white;
  height: 14px;
  width: 14px;
  margin-right: 10px;
  /* transition: all 0.3s ease-in-out; */
}
.titreFooter {
  font-size: 13px;
  font-weight: bold;
}

.footerContainer a:hover {
  color: white;
}

.mentionsText {
  margin: 0 10px;
  text-align: center;
  line-height: 20px;
}
.phoneFooter {
  font-size: 12px;
}
.logoFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footerContent {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.logoFooterImg {
  margin-bottom: 5px;
}
.logoFooter {
  /* max-width: 250px; */
  min-width: 50px;
  display: flex;
  flex-direction: column;
  /* padding: 10px 30px s12px 0px; */
}
.logoFooter svg {
  margin-right: 10px;
  font-size: 12px;
}
.logoFooter img {
  width: 150px;
  display: flex;
}
.containerResponsiveFooter {
  background-color: black;
  border-top: 2px solid var(--bg-violet-clair);
}

@media screen and (max-width: 1200px) {
  .footerContent {
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .footerContainer {
    margin: 0 auto;
  }
  .containerResponsiveFooter {
    display: flex;
    background-color: black;
    border-top: 2px solid var(--bg-violet-clair);
  }
  .logoFooter {
    padding: 5px 0;
  }
}
