.imageTop {
  width: 100%;
  height: 550px;
  background-size: cover;
  background-position: center;
}
.m-120 {
  margin: 120px auto;
}
.containerActuDetails {
  margin: 150px auto;
  padding: 40px;
  border-radius: 10px;
  background-color: var(--bg-gris);
}
.btnActuDetail {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content {
  background-color: var(--bg-gris);
  padding: 30px;
  /* transition: transform 0.3s ease; */
}
/* .content:hover {
  transform: scale(1.1);
} */
.subtitleActuDetail {
  color: var(--bg-violet-clair);
  margin-bottom: 20px;
  font-weight: 500;
}
.end {
  justify-content: flex-end;
}
.descriptionActuDetail {
  color: black;
}
.containerAgendaDansSpectacle .swiper-button-prev,
.containerAgendaDansSpectacle .swiper-button-next {
  color: var(--bg-violet-clair);
}

.imgActuDetail img {
  width: 100%;
  border-radius: 10px;
}
.imgActuDetail {
  padding-right: 30px;
}
.containerActuDetail {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.textActuDetail {
  padding-left: 30px;
  color: white;
}
.textActuDetail {
  line-height: 28px;
  font-size: 17px;
}
.minDescriptionActuDetail {
  font-size: 25px;
  color: var(--bg-violet-clair);
}

.titreActuDetail {
  font-size: 35px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: var(--bg-violet-clair);
  max-width: 650px;
}
.dateActuDetail {
  font-weight: bold;
  color: black;
}
.minDescriptionActuDetail {
  margin-bottom: 15px;
}
.linkActuDetail {
  margin-top: 20px;
  font-weight: bold;
  color: var(--bg-violet-clair);
  text-decoration: underline;
  transition: all 0.2s;
}
.linkActuDetail a:hover {
  cursor: pointer;
  color: var(--black) !important;
  transform: scale(1);
}

@media screen and (max-width: 992px) {
  .containerActuDetail {
    flex-direction: column;
  }
  .imgActuDetail {
    padding-right: 0;
    margin: 10px 0 20px 0;
  }
  .textActuDetail {
    padding-left: 0;
  }
}
