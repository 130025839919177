.card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  width: 300px;
  min-height: 450px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}
.card-title {
  font-weight: bold;
}
.card-img-overlay {
  /* d-flex flex-column */
  display: flex;
}

.card.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center center;
}
.card.card-has-bg:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
}
.card.card-has-bg:hover {
  transform: scale(1.02);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
  transition: all 0.5 cubic-bezier(0.19, 1, 0.22, 1);
}
.card.card-has-bg:hover .card-img-overlay {
  transition: all 0.8 cubic-bezier(0.19, 1, 0.22, 1);

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.card .card-body {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.card:hover {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card:hover .card-body {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.card .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);

  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(255, 255, 255, 0.246) 100%
  );
}
