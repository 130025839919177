.iconMaps {
  font-size: 23px;
  cursor: pointer;
}
.nomSpectacleAgenda {
  font-size: 22px;
  text-transform: uppercase;
  color: var(--bg-violet-clair);
  font-weight: bold;
  transition: transform 0.2s;
  transform: scale(1);
  display: flex;
}
.nomSpectacleAgenda:hover {
  transform: scale(1.01);
}
.imgItemAgenda {
  width: 100px;
  height: 100%;
  background-size: cover;
  background-position: center;
}
.lieuContainer {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.adressContainer {
  display: flex;
  align-items: flex-start;
}
.btnAgendaItem {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.imageItemAgenda {
  transition: transform 0.2s;
  transform: scale(0.98);
}
.imageItemAgenda img {
  border-radius: 10px;
}

.voirAvis,
.voirSpectacle {
  color: var(--gris);
  transition: transform 0.2s;
  transform: scale(1);
  display: flex;
}
.voirAvis:hover,
.voirSpectacle:hover,
.imageItemAgenda:hover {
  transform: scale(1.01);
}
.voirAvis {
  margin-right: 10px;
}
.containerVoir {
  display: flex;
  margin: 5px 0;
  align-items: center;
  justify-content: flex-start;
}
