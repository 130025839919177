:root {
  --bg-violet-fonce: #424864;
  --bg-violet-clair: #606992;
  --rose: #ec7ca6;
  --rose-hover: #bb6886;
  --bg-gris: #f5f5f5;
  --bg-toggle: #ebecf1;
  --black: #232323;
  --blue: #008bdc;
  --blackClair: #4d4d4d;
  --violet: #862ce0;
  --gris: #929292;
}
.bandeauFirst {
  position: absolute;
  bottom: -2px;
  width: 100%;
  left: 0;
  /* border-bottom: 3px solid white; */
}
.blocActuality {
  line-height: 22.5px;
}
.bold {
  font-weight: bolder;
}

.mb-10 {
  margin-bottom: 10px;
}
.relative {
  position: relative;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-100 {
  margin-bottom: 100px;
}

.nav {
  padding-left: 20px;
  padding-right: 20px;
}
.m70 {
  margin: 70px;
}
.preline {
  white-space: pre-line;
}
.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}
.loading span {
  margin: 30px;
}
.btn-custom {
  text-decoration: none;
  background-color: var(--rose);
  border-radius: 10px;
  color: var(--black);
  transition: all 0.3s ease-in-out;
  max-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  min-height: 40px;
  font-weight: 800;
  padding: 5px 15px;
  width: max-content;
}
.btn-custom:hover {
  transform: scale(1.05);
  color: initial;
}
.rose {
  background-color: var(--rose);
  color: black;
  font-weight: bold !important;
}
.gris {
  background-color: var(--bg-gris);
  color: var(--black);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 25px;
}
h3 {
  font-size: 20px;
}
p {
  margin-bottom: 0;
}
body {
  font-family: "Roboto";

  background-color: var(--bg-violet-fonce) !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
