.containerFormulaireHome form {
  display: flex;
  flex-direction: column;
}
.containerFormulaireHome form input {
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
  padding: 5px 10px;
}
.containerFormulaireHome form button {
  color: black;
  font-weight: bold;
  background: var(--rose);
  border: none;
}
.containerFormulaireHome form textarea {
  border-radius: 10px;
  border: none;
  padding: 10px;
  margin-bottom: 20px;
  height: 200px;
}
.btnContact {
  min-width: 190px;
}
.responseContact {
  height: 60px;
  padding-top: 10px;
}
