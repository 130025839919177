/* EVENTS */
.cardAgenda {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 270px;
  min-height: 215px;
  background-color: #e9eaef;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  transition: all 0.2s;
  transform: scale(0.98);
}
.containerAgendaSliderHome {
  margin: 100px 0 0px 0;
}
@media screen and (max-width: 435px) {
  .cardAgenda {
    width: 200px;
  }
}
/* .swiper-button-prev,
.swiper-button-next {
  color: white;
} */
.swiper-button-next {
  right: 0;
  color: var(--bg-violet-clair);
}
.swiper-button-prev {
  left: 0;
  color: var(--bg-violet-clair);
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px;
}

.infoEvent {
  background-color: var(--bg-violet-clair);
  padding: 20px;
  min-height: 115px;
  display: flex;
  white-space: normal;
  border-radius: 10px 10px 0 0;
}
.dateEvent {
  font-weight: 500;
  font-size: 14px;
  background-color: white;
  color: var(--bg-violet-clair);
  border-radius: 50%;
  text-align: center;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  padding: 5px;
  white-space: normal;
}
.dateEvent::first-line {
  color: red;
}
.bottomInfo {
  padding: 15px 20px;
  line-height: 20px;
}

.titleEvent {
  flex: 1;
  font-size: 18px;
  text-transform: uppercase;
  color: white;
}
.adresseEvent,
.heure {
  font-size: 14px;
  color: var(--bg-violet-fonce);
  margin-bottom: 0;
}
.adresseEvent {
  font-weight: 400;
}
.heure {
  font-size: 16px;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(0.98);
  will-change: transform;
  user-select: none;
  cursor: pointer;
  display: flex;
}

.cardAgenda:hover {
  cursor: pointer;
  transform: scale(1);
}

.item {
  display: inline-block;
  min-height: 250px;
  min-width: 400px;
  margin: 2em 1em;
  @media screen and (max-width: 500px) {
    min-height: 200px;
    min-width: 200px;
  }
}

.main {
  color: lighten(gray, 25%);
  grid-area: main;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: #2e3347ba;
}
.grid-item {
  color: #fff;
  padding: 3.5em 1em;
  font-size: 1em;
  font-weight: 700;
}
.btnCardAgenda {
  width: 100px;
  height: 40px;
  margin-top: 10px;
}
