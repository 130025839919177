.btnBoxSongs button {
  color: black;
  font-weight: bold;
  background: var(--rose);
  border: none;
}
.btnBoxSongs button:hover {
  background: var(--rose);
}
.titleBgImgTop {
  font-size: 4em;
  font-weight: 800;
}
.subtitleBgImgTop {
  font-size: 1.5em;
  font-weight: 800;
}
.secondBandeau {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
}

.secondBandeauFixMobile {
  transform: rotateY("180deg");
  width: 100%;
  border-bottom: 3px solid white;
  bottom: -24px;
  bottom: 0px;
}
.bandeauBgImg {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
}
.secondBgImg {
  bottom: 0px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .secondBandeauFixMobile {
    transform: rotateY("180deg");
    width: 100%;
    border-bottom: 3px solid white;
    /* bottom: -84px; */
    /* bottom: 0px; */
  }
}
