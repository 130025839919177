.containerActuality {
  /* margin-top: 120px; */
}

.postcard_preview-description {
  color: red;
  font-weight: 900 !important;
}
.grey {
  color: grey;
}

.btnActu {
  background-color: var(--bg-violet-clair);
  border-radius: 10px;
  color: white;
}

.blocActuality {
  margin: 60px 0 100px 0;
  /* padding: 60px;
  background-color: var(--bg-gris);
  border-radius: 10px; */
}

/* CARDS IMPORTE*/
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");

/* This pen */
body {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  color: #ffffff;
  text-rendering: optimizeLegibility;
  font-weight: initial;
}

.dark {
  background: #110f16;
}
.titleViolet {
  color: var(--bg-violet-clair);
}
.light {
  background: #f3f5f7;
}
.postcard__img {
  height: 300px;
  object-position: top;
}
.containerActu {
  margin-top: 70px;
}
a,
a:hover {
  text-decoration: none;
  /* transition: color 0.3s ease-in-out; */
  color: initial;
}

#pageHeaderTitle {
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
}

/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;

  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}
.postcard.dark {
  /* background-color: #18151f; */
  background-color: #e1e5ea;
}
.postcard.light {
  background-color: #e1e5ea;
}

.t-dark {
  color: #18151f;
}

a {
  color: inherit;
}

.small {
  font-size: 80%;
  color: var(--bg-violet-clair);
}

.postcard__title a {
  font-size: 1.75rem;
  color: var(--bg-violet-clair);
  text-decoration: none;
}
.postcard__title a:hover {
  /* font-size: 1.75rem; */
  color: var(--bg-violet-fonce);
  text-decoration: none;
}

.postcard__img {
  /* max-height: 180px; */
  width: 100%;
  object-fit: cover;
  position: relative;
}

.postcard__img_link {
  display: contents;
}

.postcard__bar {
  width: 50px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: var(--rose);
  transition: width 0.2s ease;
}

.postcard__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.postcard__preview-txt {
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-align: justify;
  height: 100%;
  color: var(--bg-violet-clair);
}

.postcard__tagbox {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0;
  justify-content: center;
  /* width: 100px; */
}
.tag__item {
  display: inline-block;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 3px;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}
.tag__item :hover {
  background: rgba(83, 83, 83, 0.8);
}

/* .postcard__tagbox :before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 1;
  border-radius: 10px;
} */

.postcard__tagbox :hover .postcard__bar {
  width: 100px;
}
.imgSize {
  width: 400px;
  height: auto;
  transition: transform 0.3s ease;
}
.imgSize:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 769px) {
  .imgSize {
    height: 300px;
    display: flex;
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }
  .postcard__title {
    font-size: 2rem;
  }

  .postcard__tagbox {
    justify-content: start;
  }

  .postcard__img {
    /* max-width: 300px; */
    /* max-height: 100%; */
    width: 300px;
    transition: transform 0.3s ease;
  }

  .postcard__text {
    padding: 3rem;
    width: 100%;
  }

  .media.postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }

  .imgSize:hover {
    transform: scale(1.1);
  }

  .postcard:nth-child(2n + 1) {
    flex-direction: row;
  }

  .postcard:nth-child(2n + 0) {
    flex-direction: row-reverse;
  }

  .postcard:nth-child(2n + 1) .postcard__text::before {
    left: -12px !important;
    transform: rotate(4deg);
  }

  .postcard:nth-child(2n + 0) .postcard__text::before {
    right: -12px !important;
    transform: rotate(-4deg);
  }
}
@media screen and (min-width: 1024px) {
  .postcard__text {
    padding: 2rem 3.5rem;
  }

  .postcard__text:before {
    content: "";
    position: absolute;
    display: block;

    top: -20%;
    height: 130%;
    width: 55px;
  }
  /* 
  .postcard__img {
    height: 400px;
  } */
  .postcard.dark.postcard__text:before {
    background: #18151f;
  }
  .postcard.light.postcard__text:before {
    background: #e1e5ea;
  }
}
