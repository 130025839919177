.grande-photo-container {
  height: 550px;
  width: 100%;
  top: -40px;
  position: relative;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.videoYoutube {
  max-width: 400px;
}
.div {
  background-color: red;
  width: 100px;
  height: 100px;
}
.bg-container {
  background-color: var(--bg-violet-fonce);
  position: relative;
}
.dateResponsive {
  display: none;
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_controls-section
  > div.rhap_additional-controls {
  justify-content: center;
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_controls-section
  > div.rhap_main-controls
  > button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button
  > svg
  > path {
  color: var(--rose);
}
.playlist {
  background-color: rgb(35, 35, 35, 0.8);
  /* background-color:; */
  /* position: absolute; */
  /* margin-bottom: -100px; */
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.playlistItem {
  color: white;
  font-family: "Roboto";
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 20px;
}
.playlistItem:last-child {
  color: white;
  font-family: "Roboto";
  padding: 15px 20px;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.playlistItem:hover {
  color: white;
  font-family: "Roboto";
  background-color: rgb(236, 124, 166, 0.4);
}
.playlistItem p {
  margin-bottom: 0;
}
.marginRight {
  margin-right: 10px;
}
.marginLeft {
  margin-left: 10px;
}
.containerCustom {
  /* max-width: 1280px; */
  margin: auto;
  margin-bottom: 80px;
  padding: 0 20px;
}
p {
  font-family: "Roboto";
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_progress-section
  > div.rhap_progress-container
  > div
  > div.rhap_download-progress {
  background-color: var(--rose);
}
#root
  > div
  > div:nth-child(2)
  > div
  > div.containerCustom.container
  > div:nth-child(3)
  > div:nth-child(2) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_progress-section
  > div.rhap_progress-container
  > div
  > div.rhap_progress-indicator {
  background-color: var(--bg-violet-fonce);
}
#root
  > div
  > div:nth-child(2)
  > div
  > div.containerCustom.container
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_controls-section
  > div.rhap_main-controls
  > button.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button
  > svg {
  color: var(--rose);
}
#root
  > div
  > div:nth-child(2)
  > div
  > div.containerCustom.container
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_progress-section
  > div.rhap_progress-container
  > div
  > div.rhap_progress-filled {
  background-color: var(--rose);
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom
  > div.containerLecteurAudio
  > div
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_progress-section
  > div.rhap_progress-container
  > div
  > div.rhap_progress-filled {
  background-color: var(--bg-violet-clair);
}

.textReseaux {
  margin-bottom: 20px;
}
.agendaContainer {
  background-color: var(--bg-gris);
  border-radius: 10px;
  margin: 60px 0;
}

.titleAgenda {
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--bg-violet-clair);
}
.containerAgendaDansSpectacle {
  background-color: white;
  padding: 40px 0 80px 0;
}

.containerAgendaDansSpectacle .swiper-button-prev,
.containerAgendaDansSpectacle .swiper-button-next {
  color: var(--bg-violet-clair);
}

.btnBoxSongs {
  width: 150px;
}
.itemAgendaContainer {
  padding: 12px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  background-color: var(--bg-gris);
  margin-bottom: 30px;
}
#root
  > div
  > div:nth-child(2)
  > div.containerCustom.container
  > div:nth-child(3)
  > div:nth-child(2) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
/* .itemAgendaContainer:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
} */
.itemAgendaContainer:last-child {
  border-bottom: none;
}
.month {
  font-size: 2em;
  color: var(--bg-violet-clair);
  font-weight: 600;
  margin-bottom: 20px;
}
.contentAgenda {
  background-color: var(--bg-gris);
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.bandeauYoutube {
  margin: 80px 0;
  padding: 80px 0;
  /* max-width: 1280px; */
  margin: auto !important;
}
.dateContainer {
  border-right: 3px solid var(--bg-violet-clair);
  padding-right: 15px;
}
.titleAgenda {
  margin-top: 60px;
}

.subtitle {
  font-size: 18px;
  color: var(--gris);
  text-align: end;
  line-height: 20px;
  text-transform: capitalize;
}
.date {
  font-size: 45px;
  color: var(--bg-violet-clair);
  font-weight: 600;
  text-align: end;
  line-height: 40px;
}
.ville {
  font-size: 18px;
  color: var(--bg-violet-clair);
  line-height: 20px;
  font-weight: 600;
}

.btnGps {
  color: white;
  border-radius: 10px;
  outline: none;
  border: transparent;
  text-decoration: none;
}
.btnGpsResponsive {
  font-size: 23px;
}
.btnGpsResponsive:hover {
  color: var(--bg-violet-fonce);
  cursor: pointer;
}
.adresse {
  font-size: 16px;
  text-align: left;
  color: var(--gris);
  max-width: 250px;
  line-height: 20px;
  margin-top: 5px;
}
.bandeauYoutubeContainer {
  background-color: var(--bg-violet-clair);
}
.textYoutube {
  padding: 30px 10px 0px 10px !important;
}
.bandeau-sur-grande-image {
  padding: 40px;
  margin-top: -430px;
  width: 100%;
}
.bandeau-lecteur-infos {
  display: flex;
  margin: auto;
}
.title {
  color: var(--bg-gris);
  margin-bottom: 5px;
}
.minDescription {
  color: var(--bg-gris);
  font-size: 1em;
  margin-bottom: 5px;
}
.couverture-album {
  width: auto;
  height: 350px;
}
.title-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: 20px;
  z-index: 1;
}
.lecteur-container {
  position: relative;
  background-position: top;
  background-image: url(https://res.cloudinary.com/dckady18i/image/upload/v1644308363/spectacle/61fbebb…/affiche/preview.jpg);
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
  background-size: cover;
  width: 350px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.containerLecteurAudio {
  display: flex;
  align-items: center;
  margin-top: -86px;
  border-top-left-radius: 10px;
}
.btnOpenPlaylist {
  background-color: rgb(35, 35, 35, 0.8);
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.btnPlaylist {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 10px 10px 10px 10px;

  background-color: rgb(35, 35, 35, 0.5);
}
.btnPlaylist p {
  color: white;
  font-size: 0.9em;
  margin-bottom: 0;
}
.lecteurAudio {
  width: 100%;
}

button.rhap_button-clear {
  color: white;
}

button.rhap_button-clear.rhap_main-controls-button {
  color: white;
}
.rhap_volume-indicator {
  color: white;
}

.rhap_volume-bar-area {
  color: white;
}
#root
  > div
  > div:nth-child(2)
  > div.rhap_container.rhap_loop--off.rhap_play-status--paused.lecteurAudio
  > div.rhap_main.rhap_horizontal-reverse
  > div.rhap_progress-section
  > div.rhap_progress-container
  > div
  > div.rhap_download-progress {
  background-image: linear-gradient(
    to right,
    #424864,
    #5a547a,
    #785e8c,
    #9a679a,
    #bd70a3,
    #c173a6,
    #c575aa,
    #c978ad,
    #ad75ad,
    #9172a8,
    #776e9f,
    #606992
  );
}

div.rhap_volume-controls > div {
  display: none;
}

.rhap_time.rhap_total-time,
.rhap_time.rhap_current-time {
  color: white;
}
.rhap_header {
  color: white;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto";
  font-style: italic;
}
.bg {
  background-color: rgba(96, 105, 146, 0.5);
  padding: 20px;
  margin-left: 50px;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.rhap_skip-button {
  border-radius: 50px;
}
.affiche-responsive {
  max-width: 300px;
  height: auto;
}

.affiche-responsive-container {
  display: none;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
}
.title-responsive {
  margin-top: -20px;
  background-color: var(--black);
  padding: 10px 15px;
  border-radius: 20px;
  color: var(--bg-gris);
  font-family: "Roboto";
}
.img-responsive-text {
  background-color: green;
}
.minDescription-responsive {
  text-align: center;
  color: var(--bg-gris);
  display: none;
}
.stars {
  display: flex;
  font-size: 0.8em;
  color: var(--blue);

  padding: 5px 0;
}
.linkBoxSongs {
  text-decoration: none;
  color: var(--bg-gris);
  /* margin-left: 10px; */
  font-family: "Roboto";
  text-decoration: underline;
  font-size: 1em;
}
.linkBoxSongContainer {
  padding: 10px 0;
}
.little-infos-container {
  margin-left: 440px;
  padding: 0 20px;
}
.avisTitle {
  color: var(--bg-gris);
  padding: 5px 0;
}
.lecteurResponsive {
  display: none;
}
.buttonsAvis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* TOGGLE */
.toggle-container {
  margin-top: 60px;
}
/* 750px	970px	1170px */
@media (max-width: 850px) {
  .bandeau-lecteur-infos {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .bandeau-sur-grande-image {
    margin-top: 0;
    padding: 0px;
  }

  .grande-photo-container {
    display: none;
  }

  .title-container {
    width: 100%;
    text-align: center;
  }
  .bg {
    margin-left: 0px;
    background-color: transparent;
  }
  .lecteur-container {
    width: 60%;
    max-width: 350px;
    height: 400px;
    margin: 0;
  }
  .avis-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttonsAvis {
    padding: 5px 0;
  }
  .little-infos-container {
    margin-left: 0px;
    background-color: var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 20px 0; */
  }
}
@media (max-width: 970px) {
  .affiche-responsive {
    width: 60%;
    height: auto;
    border-radius: 20px;
    margin-top: 100px;
  }
  .affiche-responsive-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .minDescription-responsive {
    display: flex;
    justify-content: center;
    margin: 40px 0 50px 0;
  }
}

@media (max-width: 800px) {
  .textYoutube {
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 576px) {
  .subtitle,
  .date {
    display: none;
  }
  .adresse {
    display: flex;
  }
  .dateContainer {
    border-right: none;
  }
  .date {
    font-size: 18px;
    line-height: 18px;
  }
  .lieuContainer {
    margin-top: 20px;
    display: flex;
    padding-left: 0px !important;
    /* flex-direction: row; */
  }
  .itemAgendaContainer {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .dateResponsive {
    display: flex;
    color: var(--rose);
    font-weight: 600;
  }
  .dateContainer {
    display: none;
  }
  .ville {
    font-size: 20px;
  }
}
