/* Presentation */
.containerPresentation {
  display: flex;
  justify-content: center;
}
.presentation {
  /* flex: 2; */
  max-width: 650px;
}
.titlePresentation {
  font-size: 48px;
  line-height: 50px;
  font-weight: bolder;
  margin: 0;
  margin-bottom: 20px;
}
.titlePresentation span {
  font-size: 37px;
  line-height: 33.5px;
  font-weight: normal;
}

.txtPresentation p {
  font-size: 16px;
  line-height: 22.5px;
  text-align: justify;
  letter-spacing: 0.8px;
}
.txtPresentation h3 {
  font-size: 30px;
  font-weight: bold;
}
.txtPresentation li {
  list-style: circle;
  margin-left: 20px;
}
.imgPresentation {
  height: 400px;
  margin-right: 50px;
}
.imgPresentation img {
  height: 100%;
  border-radius: 10px;
}

@media screen and (max-width: 1200px) {
  .imgPresentation {
    display: none;
  }
}
