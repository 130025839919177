.itemSpectacle a {
  margin: 10px 0;
  text-decoration: none;
  color: white;
}

.spectacles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.containerSpectacles {
  color: white;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.spectacles .card {
  margin: 0 40px 40px 40px;
}

@media screen and (max-width: 991px) {
  .spectacles .card {
    margin: 0 20px 40px 0;
  }
}
@media screen and (max-width: 535px) {
  .titleSpectacles {
    width: 400px;
  }
  .titleSpectacles h2 {
    font-size: 30px;
  }
}
@media screen and (max-width: 430px) {
  .titleSpectacles {
    width: 350px;
  }
  .titleSpectacles h2 {
    font-size: 20px;
  }
  .spectacles .card {
    margin: 0 0px 20px 0;
  }
}
