.cardXs {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  width: 250px;
  min-height: 250px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
}

.cardXs.card-has-bg {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center center;
}

.cardXs.card-has-bg:hover {
  transform: scale(1.02);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
  transition: all 0.5 cubic-bezier(0.19, 1, 0.22, 1);
}
.cardXs.card-has-bg:hover .card-img-overlay {
  transition: all 0.8 cubic-bezier(0.19, 1, 0.22, 1);

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.cardXs .card-body {
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cardXs:hover {
  cursor: pointer;
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.cardXs:hover .card-body {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
.cardXs .card-img-overlay {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);

  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(255, 255, 255, 0.246) 100%
  );
}

.cardXs .card-img-overlay {
  position: relative;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.5);
}
.titleCardCustom {
  font-size: 20px;
  font-weight: bold;
}
.card-body p {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
