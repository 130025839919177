.containerActus {
  position: relative;
  background: var(--bg-gris);
  color: var(--bg-violet-fonce);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  /* border-bottom: 5px solid var(--rose);
  border-left: 5px solid var(--rose); */
  margin-right: 60px;
  max-width: 450px;
}
.dateActu {
  color: grey;
  margin-bottom: 10px;
}

.containerActus:first-child {
  margin-top: 60px;
}
.containerActus:nth-child(3) {
  margin-top: 60px;
}
.containerActus:nth-child(4) {
  margin-top: 15px;
}
.containerActus:nth-child(2),
.containerActus:nth-child(3) {
  background-color: var(--bg-violet-fonce);
  color: white;
}

.containerActus:nth-child(2) .containerTextActus h3,
.containerActus:nth-child(3) .containerTextActus h3,
.containerActus:nth-child(2) .containerTextActus p,
.containerActus:nth-child(2) .containerTextActus .dateActu,
.containerActus:nth-child(3) .containerTextActus p,
.containerActus:nth-child(3) .containerTextActus .dateActu {
  color: white;
}
.containerActus:nth-child(2) .containerTextActus span,
.containerActus:nth-child(3) .containerTextActus span {
  color: var(--rose);
  font-weight: bold;
}

.containerTextActus {
  width: 100%;
}
.containerTextActus h3 {
  color: var(--bg-violet-clair);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
}
.containerTextActus p {
  color: var(--bg-violet-clair);
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
  margin-bottom: 20px;
}
.sliderActuHome {
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .containerActus {
    justify-content: center;
  }
  .containerActus:nth-child(3),
  .containerActus:nth-child(2),
  .containerActus:nth-child(4),
  .containerActus:nth-child(1) {
    margin-top: 30px;
  }
  .containerActus:nth-child(2),
  .containerActus:nth-child(4) {
    background-color: var(--bg-violet-fonce);
    color: white;
  }
  .containerActus:nth-child(3) {
    background-color: white;
    color: var(--bg-violet-clair);
  }

  .containerActus:nth-child(2) .containerTextActus h3,
  .containerActus:nth-child(4) .containerTextActus h3,
  .containerActus:nth-child(2) .containerTextActus p,
  .containerActus:nth-child(2) .containerTextActus .dateActu,
  .containerActus:nth-child(4) .containerTextActus p,
  .containerActus:nth-child(4) .containerTextActus .dateActu {
    color: white;
  }
  .containerActus:nth-child(3) .containerTextActus h3,
  .containerActus:nth-child(3) .containerTextActus p,
  .containerActus:nth-child(3) .containerTextActus .dateActu {
    color: var(--bg-violet-clair);
  }
  .containerActus:nth-child(2) .containerTextActus span,
  .containerActus:nth-child(4) .containerTextActus span {
    color: var(--rose);
    font-weight: bold;
  }
}
